
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import Header from "@/components/Header.vue";
import { VacationLeaveService } from "@/services/attendance/leave/VacationLeaveService";
import { LeaveTypeService } from "@/services/attendance/leavetype/LeaveTypeService";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import {
  FileAttendanceHelper,
  UploadStatus,
  UploadMessage,
} from "@/utils/FileAttendanceHelper";
import Utils from "@/utils/Utils";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import SystemUtils from "@/utils/SystemUtils";
import SendPage from "@/views/attendance/apply/SendPage.vue";
export default defineComponent({
  name: "leaveApply",
  components: {
    Header,
  },
  data() {
    const router = useRouter();
    const fileHelper = new FileAttendanceHelper();
    const leaveService = new VacationLeaveService();
    const leaveTypeService = new LeaveTypeService();
    const workflowService = new WorkflowService();
    const currentUser: any = SystemUtils.loginUser;

    return {
      query: useRoute().query,
      router,
      leaveService: leaveService,
      leaveTypeService: leaveTypeService,
      workflowService: workflowService,
      fileHelper,
      btnIconRight: ["iconduihao"],
      btnIconRightHasDelete: ["iconduihao", "iconhuishouzhan"],
      btnSizeRight: ["50px"],
      btnSizeRightHasDelete: ["50px", "50px"],
      vacationTypeList: new Array<any>(),
      vacationType: "", // 请假类型
      vacationTypeId: "",
      vacationCode: "",
      extType: "", //请假补充类型
      startDate: "", // 请假开始时间
      startNoon: "AM",
      endDate: "", //请假结束时间
      endNoon: "PM",
      reason: "",
      type: 0, //0 请假申请  1补假申请
      datetimeMax: "",
      datetimeMin: "",
      photosMessage: new Array<any>(),
      headerType: 0, //0：保存   1：保存 删除
      id: "", //主键
      procId: "",
      taskId: "",
      procStatus: -1,
      businessId: "",
      attachments: new Array<any>(),
      selectUsers: new Array<any>(), //抄送人
      ccUsers: new Array<any>(), //抄送人
      firstLeaders: new Array<any>(),
      attachmentRequired: 0, //是否需要附件：0不需要，1：请假时需要；2销假时需要；3:请假或销假时至少有一个
      showExtType: false, //是否显示请假补充类型
      handleType: 99999, //0:待我审批  1：我的已办  99999：新增
      isClick: true,
      days: 0, //请假天数
      cjCode: "LeaveType_Maternity", //产假code
      burujiaCode: "LeaveType_Lactation", //哺乳假code
      userId: "",
      showDelete: false,

      maxCount: 3,
    };
  },
  mounted() {
    if (this.query) {
      if (this.query.type) {
        this.type = Number(this.query.type);
      } else {
        this.type = 0;
      }
      if (this.query.handleType) {
        this.handleType = parseInt(this.query.handleType as string);
      }
      if (this.query.id) {
        this.id = this.query.id as string;
        this.procId = this.query.procId as string;
        this.taskId = this.query.taskId as string;
        this.procStatus = Number(this.query.procStatus);
      }
    }

    if (this.type == 1) {
      this.datetimeMax = moment(new Date()).format("yyyy-MM-DD") + "";
      let dateMax = new Date(this.datetimeMax);
      let dateMin = new Date(dateMax);
      dateMin.setFullYear(dateMin.getFullYear() - 5);
      dateMin.setDate(dateMin.getDate() + 5);
      this.datetimeMin = moment(dateMin).format("yyyy-MM-DD") + "";
    } else {
      this.datetimeMin = moment(new Date()).format("yyyy-MM-DD") + "";
      let dateMin = new Date(this.datetimeMin);
      let dateMax = new Date(dateMin);
      dateMax.setFullYear(dateMax.getFullYear() + 5);
      dateMax.setDate(dateMax.getDate() - 5);
      this.datetimeMax = moment(dateMax).format("yyyy-MM-DD") + "";
    }
    this.initData();
  },
  methods: {
    initData() {
      this.getVacationType();
      if (this.handleType == 99999) {
        this.startNoon = "AM";
        //  this.startNoonText = '上午';
        this.endNoon = "PM";
        //    this.endNoonText = '下午';
      } else {
        this.getDetail();
      }
    },
    getDetail() {
      let params = { id: this.id };
      this.leaveService
        .getInfo(params)
        .then((result: any) => {
          if (result) {
            this.vacationTypeId = result.vacationTypeId;
            this.getVacationInfo(result.vacationTypeId);
            this.startDate = result.startDate;
            this.startNoon = result.startNoon;
            // this.startNoonText = result.startNoon == 'AM' ? '上午' : '下午';
            this.endDate = result.endDate;
            this.endNoon = result.endNoon;
            //  this.endNoonText = result.endNoon == 'AM' ? '上午' : '下午';
            this.reason = result.reason;
            this.attachments = [];

            this.selectUsers = result.ccUsers;
            this.selectUsers.forEach((u) => {
              this.ccUsers.push(u.userId);
            });

            for (let i = 0; i < result.attachments.length; i++) {
              let file = {
                base64Image: null,
                httpPath: result.attachments[i].path,
                relativePath: null,
                path: result.attachments[i].path,
                size: null,
                type: 0,
                ext: "",
              };
              this.attachments.push(file);
            }
            this.extType = result.extType;
            if (this.extType != null && this.extType != "") {
              this.showExtType = true;
            } else {
              this.showExtType = false;
            }
          }
        })
        .catch(() => {});
    },
    //获取假期类型
    getVacationType() {
      this.leaveTypeService
        .getVacationType()
        .then((result: any) => {
          if (result) {
            this.vacationTypeList = result;
            if (this.vacationType == "" && result.length > 0) {
              this.vacationType = result[0].name;
              this.vacationTypeId = result[0].id;
              this.attachmentRequired = result[0].attachmentRequired;
            }
          }
        })
        .catch(() => {});
    },
    getVacationInfo(val: any) {
      this.vacationTypeId = val;
      let list = this.vacationTypeList.filter((ele: any) => {
        return ele.id == val;
      });
      if (list.length > 0) {
        this.vacationType = list[0].name;
        this.attachmentRequired = list[0].attachmentRequired;
        this.vacationCode = list[0].code;
      }
    },
    //请假类型
    selectLeaveType() {
      let selectOperate: any = document.getElementById("leave_type");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindLeaveType(ev: any) {
      this.vacationTypeId = ev;
      this.getVacationInfo(this.vacationTypeId);
      if (this.vacationCode == this.cjCode) {
        this.showExtType = true;
      } else {
        this.showExtType = false;
      }
    },
    //补充请假类型
    selectExtType() {
      let selectOperate: any = document.getElementById("ect_type");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindExtType(ev: any) {
      this.extType = ev;
    },
    //开始日期
    selectStartNoon() {
      let selectOperate: any = document.getElementById("start_noon");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindStartNoon(ev: any) {
      this.startNoon = ev;
    },
    //结束日期
    selectEndNoon() {
      let selectOperate: any = document.getElementById("end_noon");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindEndNoon(ev: any) {
      this.endNoon = ev;
    },
    //抄送
    async pickUserHandler() {
      let selects: any = new Array();
      this.selectUsers.forEach((ele: any) => {
        selects.push({
          id: ele.userId,
          userId: ele.userId,
          name: ele.userName,
        });
      });
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: selects,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.data) {
        let users = data.data;
        this.ccUsers = [];
        this.selectUsers = [];
        users.forEach((o: any) => {
          this.ccUsers.push(o.id);
          this.selectUsers.push({
            userId: o.id,
            userName: o.name,
          });
        });
      }
    },
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
    finishHandler(item: any) {
      if (item == "0_icon") {
        this.saveHandler();
      } else {
        this.deleteHandle();
      }
    },
    //保存
    saveHandler() {
      let {
        id,
        vacationType,
        vacationCode,
        extType,
        vacationTypeId,
        attachmentRequired,
        startDate,
        startNoon,
        endDate,
        endNoon,
        days,
        reason,
        attachments,
        procId,
        taskId,
        ccUsers,
      } = this;

      if (!vacationType.replace(/\s+/g, "")) {
        Utils.presentToastWarning("请选择请假类型");
        return;
      }
      if (vacationCode == this.cjCode && extType == "") {
        Utils.presentToastWarning("请选择请假补充类型");
        return;
      }
      if (startDate == "" || startDate == "点击选择时间") {
        Utils.presentToastWarning("请选择请假开始时间");
        return;
      }
      if (endDate == "" || endDate == "点击选择时间") {
        Utils.presentToastWarning("请选择请假结束时间");
        return;
      }
      if (!reason.replace(/\s+/g, "")) {
        Utils.presentToastWarning("请输入请假原因");
        return;
      }

      let charFlag = false;
      let date1 = new Date(
        moment(startDate).format("yyyy-MM-DD") +
          " " +
          (startNoon == "AM" ? "09:00:00" : "15:00:00")
      );
      let date2 = new Date(
        moment(endDate).format("yyyy-MM-DD") +
          " " +
          (endNoon == "AM" ? "09:00:00" : "15:00:00")
      );

      if (date1.getTime() > date2.getTime()) {
        charFlag = true;
      }
      if (charFlag) {
        Utils.presentToastWarning("请假开始时间不能晚于请假结束时间");
        return;
      }
      if (Utils.isInCludeEmoji(reason)) {
        charFlag = true;
      }
      if (charFlag) {
        Utils.presentToastWarning("不可以包含表情");
        return;
      }
      //哺乳假只能请半天
      if (vacationCode == this.burujiaCode) {
        endNoon = startNoon;
      }
      //是否需要附件：0不需要，1：请假时需要；2销假时需要；3:请假或销假时至少有一个
      if (attachmentRequired == 1 && attachments.length == 0) {
        Utils.presentToastWarning("请上传附件");
        return;
      }
      //生育产假请假时必须上传附件
      if (
        vacationCode == this.cjCode &&
        extType == "生育产假" &&
        attachments.length == 0
      ) {
        Utils.presentToastWarning("请上传附件");
        return;
      }
      if (vacationCode != this.cjCode) {
        extType = "";
      }

      let params = {
        id,
        vacationType,
        vacationTypeId,
        extType,
        startDate,
        startNoon,
        endDate,
        endNoon,
        days,
        reason,
        attachments,
        procId,
        taskId,
        ccUsers,
      };
      if (!this.isClick) return;
      this.isClick = false;
      let paramNextStepUser = { VacationTypeId: this.vacationTypeId };
      //获取下一步骤处理人
      this.leaveService
        .getNextStepUsers(paramNextStepUser)
        .then((result: any) => {
          let that = this;
          if (result && result.length > 0) {
            this.firstLeaders = result;
            this.saveLeave(
              params,
              function (loadingCall: any) {
                that.presentModal();
                loadingCall();
                setTimeout(() => {
                  that.isClick = true;
                }, 1000);
              },
              () => {}
            );
          } else {
            this.leaveService
              .beforeSubmit(params)
              .then((result: any) => {
                if (result) {
                  Utils.confirmDialog1(
                    "确认",
                    "请确认是否提交请假申请",
                    function () {
                      that.isClick = false;
                      that.saveLeaveHandler(
                        params,
                        () => {},
                        () => {
                          setTimeout(() => {
                            that.isClick = true;
                          }, 1000);
                        }
                      );
                    },
                    function () {
                      that.isClick = true;
                    }
                  );
                } else {
                  that.isClick = true;
                  Utils.presentToastWarning(result.error);
                }
              })
              .catch((ex: any) => {
                that.isClick = true;
                if (ex && ex.data && ex.data.error) {
                  Utils.presentToastWarning(ex.data.error);
                }
              });
          }
        })
        .catch(() => {});
      /*
      let that = this;
      this.id = "";
      this.businessId = "a";
      this.taskId = "b";
      this.procId = "c";
      this.firstLeaders = [
        {
          name:"王利军",
          orgName:"智能机器人研发部",
          id:"1",
        },
        {
          name:"高照迁",
          orgName:"智能机器人研发部",
          id:"2",
        },
        {
          name:"李宗科",
          orgName:"智能机器人研发部",
          id:"3",
        },
      ];
      that.presentModal();
      setTimeout(() => {
        that.isClick = true;
      }, 1000);*/
    },
    saveLeave(params: any, callback: any, loadingcall: any) {
      this.leaveService
        .submit(params)
        .then((result: any) => {
          this.isClick = true;
          if (result) {
            this.id = result.businessId;
            this.businessId = result.businessId;
            this.taskId = result.taskId;
            this.procId = result.procId;
            callback(loadingcall);
          } else {
            loadingcall();
            this.isClick = true;
            Utils.presentToastWarning("提交失败");
          }
        })
        .catch(() => {
          loadingcall();
          this.isClick = true;
        });
    },
    saveLeaveHandler(params: any, ok: any, fail: any) {
      let that = this;
      this.saveLeave(
        params,
        function (ok: any) {
          //发送
          let paramSend = {
            procId: that.procId,
            taskId: that.taskId,
            listReciveVar: [],
            option: "",
          };
          that.sendWorkflow(paramSend, () => {
            ok();
          });
        },
        function () {
          fail();
        }
      );
    },
    sendWorkflow(paramSend: any, callback: any) {
      if (this.userId) {
        let userIds = new Array();
        userIds.push(this.userId);
        paramSend.listReciveVar = [
          {
            key: "assigneeList",
            value: userIds,
          },
        ];
      }
      this.workflowService
        .send(paramSend)
        .then((resultSend: any) => {
          if (resultSend) {
            if (resultSend.result) {
              callback();
              Utils.presentToastSuccess("提交成功");
              let that = this;
              setTimeout(() => {
                that.router.go(-1);
              }, 500);
            } else {
              callback();
            }
          }
        })
        .catch(() => {
          callback();
          this.isClick = true;
        });
    },
    /**
     * 选下一步处理人
     */
    async presentModal() {
      const modal = await modalController.create({
        component: SendPage,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            businessId: this.businessId,
            taskId: this.taskId,
            procId: this.procId,
            receiveParameters: "assigneeList",
            userList: this.firstLeaders,
          },
        },
      });
      await modal.present();
      // this.sendModal = modal;
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.save) {
        let that = this;
        setTimeout(() => {
          that.router.go(-1);
        }, 500);
      }
    },
    deleteHandle() {
      let that = this;
      Utils.confirmDialog("确认", "确认删除该申请？", function () {
        let params = { id: that.id };
        that.leaveService
          .delete(params)
          .then((result: any) => {
            that.isClick = true;
            Utils.presentToastWarning("删除成功");
            setTimeout(() => {
              that.router.go(-1);
            }, 500);
          })
          .catch(() => {
            that.isClick = true;
          });
      });
    },

    //上传图片-自定义选择框
    async bindUploadPicture() {
      //打开选择框
      this.fileHelper.openFileSheet(
        (path: any) => {
          if (path) {
            let pic = {
              url: "",
              status: UploadStatus.Uploading,
              message: UploadMessage.Uploading,
              isImage: true,
            };
            this.photosMessage.push(pic);
            this.fileHelper.uploadFile(path, this.leaveService.uploadFile()).then(
              (result: any) => {
                let file = {
                  base64Image: result.httpPath,
                  httpPath: result.httpPath,
                  relativePath: result.relativePath,
                  path: result.relativePath,
                  size: result.size,
                  type: result.type == "pic" ? 0 : 1,
                  ext: result.ext,
                };
                this.attachments.push(file);
                const index: number = this.photosMessage.length - 1;
                this.photosMessage[index].url = result.httpPath;
                this.photosMessage[index].status = UploadStatus.Done;
                this.photosMessage[index].message = UploadMessage.Done;
              },
              (e: any) => {
                this.photosMessage.splice(this.photosMessage.length - 1, 1);
              }
            );
          }
        },
        (error: string) => {
          Utils.presentToastWarning(error);
        }
      );
    },

    bindOverSize(file: any) {
      Utils.presentToastWarning("文件大小不能超过10MB");
    },

    //删除文件
    bindDeletePicture(ev: any) {
      let url = ev.url;
      for (let index = 0; index < this.photosMessage.length; index++) {
        if (url === this.photosMessage[index].url) {
          this.photosMessage.splice(index, 1);
          this.attachments.splice(index, 1);
        }
      }
    },
  },
});
